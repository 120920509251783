<template>
  <div class="projectionContainer">
    <CSBreadcrumb />
    <ProjectionComp
      style="height: 884px"
      show-control
      :isSubmitting="isSubmitting"
      @changeField="changeItem"
      @onSave="saveMission"
      :flight="flightHeight"
      :back="courseReversalHeight"
      :default-way-points="wayPoints"
      :default-name="name"
    ></ProjectionComp>
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import ProjectionComp from "@/views/Projection/ProjectionComp";
export default {
  name: "projectionMap",
  data() {
    return {
      name: "",
      isSubmitting: false,
      flightHeight: "",
      courseReversalHeight: "",
      wayPoints: [],
    };
  },
  components: {
    CSBreadcrumb,
    ProjectionComp,
  },
  methods: {
    changeItem(e) {
      console.log(e);
      console.log('成功抛出事件');
      this.$vc.emit('this.$route.path','saveMission',{pot:e});
    },
    saveMission() {
      this.$router.go(-1)
    },
  },
};
</script>

<style>
</style>